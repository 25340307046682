<template>
    <div :class="{'nav-open': $showSidebar}">
        <notifications position="top center" width="400"></notifications>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
        name: 'app',
        data() {
            return {
            }
        },
        created: async function(){
            if( this.$route.name !== 'login' && this.$route.name !== 'logout'){
                /** preload user data */
                console.info("checking login");
                await this.$api.user.current();
            }
        }
    }
</script>
