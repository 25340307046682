import DashboardLayout from "@/layout/DashboardLayout.vue";

import NotFound from "@/pages/NotFoundPage.vue";
import Error404List from "@/pages/Lists/Error404.vue";
import Dashboard from "@/pages/Dashboard.vue";

import Login from "@/pages/Login.vue";
import Logout from "@/pages/Logout.vue";
import SingleUser from "@/pages/Editors/User.vue";
import SingleRole from "@/pages/Editors/Role.vue";
import UserProfile from "@/pages/Users/UserProfile.vue";
import RecuperaPassword from "@/pages/RecuperaPassword.vue";
import ResetPassword from '@/pages/ResetPassword.vue'
import UserList from "@/pages/Users/UserList.vue";
import RoleList from "@/pages/Lists/Role.vue";
import RedazioneList from "@/pages/Users/RedazioneList.vue";
import RedazioneDetail from "@/pages/Users/RedazioneDetail.vue";

import EventList from "./pages/Lists/SimpleEventList.vue";
import Event from "./pages/Editors/SimpleEvent.vue";
import NewsList from "@/pages/Lists/News.vue";
import News from "@/pages/Editors/News.vue";
import IATList from "@/pages/Lists/IAT.vue";
import IAT from "@/pages/Editors/IAT.vue";
import ItinerariList from "@/pages/Lists/Itinerari.vue";
import Itinerary from "./pages/Editors/Itinerary.vue";
import InteressiList from "@/pages/Lists/Interessi.vue";
import Interessi from "@/pages/Editors/Interessi.vue";
import ViaEmiliaList from "@/pages/Lists/ViaEmilia.vue";
import ViaEmilia from "@/pages/Editors/ViaEmilia.vue";
import WebcamList from "@/pages/Lists/Webcam.vue";
import Webcam from "@/pages/Editors/Webcam.vue";
import OpenDataList from "@/pages/Lists/OpenData.vue";
import OpenData from "@/pages/Editors/OpenData.vue";
import DownloadList from "@/pages/Lists/Download.vue";
import Download from "@/pages/Editors/Download.vue";
import Gallery from "@/pages/Gallery.vue";
import PageList from "@/pages/Lists/Page.vue";
import Page from "@/pages/Editors/Page.vue";
import TipologiaLuoghiList from "@/pages/Lists/TipologiaLuoghi.vue";
import LuoghiList from "@/pages/Lists/Luogo.vue";
import Luogo from "@/pages/Editors/Luogo.vue";
import Tags from "@/pages/Lists/Tags";
import TagsDetail from "@/pages/Editors/TagsDetail";
import HomeSettings from "./pages/settings/HomeSettings.vue";
import HomeSettingsExtra from "./pages/settings/HomeSettingsExtra.vue";
import MakeXPList from "@/pages/Lists/MakeXP";
import TrekkSoftList from "@/pages/Lists/TrekkSoft";
import CategoryList from "@/pages/Lists/Category";
import TemplateList from "./pages/Lists/TemplateList"
/* Destinazioni */

import Allegati from "./pages/Lists/Allegati";
import BlogsList from "./pages/Lists/BlogsList";
import EsperienzeList from "./pages/Lists/EsperienzeList";
import OperatoriList from "./pages/Lists/OperatoriList";
import ProposteSoggiornoList from "./pages/Lists/ProposteSoggiornoList";


import Blogs from "./pages/Editors/Blogs";
import Esperienza from "./pages/Editors/Esperienza";
import Operatore from "./pages/Editors/Operatore";
import ProposteSoggiorno from "./pages/Editors/ProposteSoggiorno";

import PlaceList from "./pages/Lists/PlaceList.vue";
import StayList from "./pages/Lists/StayList.vue";
import DestinationList from "./pages/Lists/DestinationList.vue";
import EatList from "./pages/Lists/EatList.vue";
import GuideList from "./pages/Lists/GuideList.vue";
import RentList from "./pages/Lists/RentList.vue";
import SportList from "./pages/Lists/SportList.vue";
import StationList from "./pages/Lists/StationList.vue";

import Place from "./pages/Editors/Place.vue";
import Eat from "./pages/Editors/Eat.vue";
import Destination from "./pages/Editors/Destination.vue";
import Guide from "./pages/Editors/Guide.vue";
import Rent from "./pages/Editors/Rent.vue";
import Sport from "./pages/Editors/Sport.vue";
import Station from "./pages/Editors/Station.vue";
import Stay from "./pages/Editors/Stay.vue";
import Template from "./pages/Editors/Template"

import MenuEditor from "@/pages/Lists/Menu";


import LicenzeList from "@/pages/Lists/Licenze";
import Licenza from "@/pages/Editors/Licenze";

import AttendancesStats from './pages/Stats/AttendancesStats';
import ServicesStats from './pages/Stats/ServicesStats';
import RatesStats from './pages/Stats/RatesStats';
import SearchesStats from './pages/Stats/SearchesStats';

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/password/reset",
    name: "recovery",
    component: RecuperaPassword
  },
  {
    path: "/password/reset/:code",
    name: "recovery-change",
    component: ResetPassword,
    props: true
  },
  {
    //   base: '/backend/',
    path: "/",
    component: DashboardLayout,
    redirect: "/login",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "users",
        name: "Utenti",
        component: UserList,
        meta: {
          permissions: [{
            role: 'redazione',
            access: false,
            redirect: 'not-found'
          }]
        }
      },
      {
        path: "users/roles",
        name: "Ruoli",
        component: RoleList,
        meta: {
          permissions: [{
            role: 'redazione',
            access: false,
            redirect: 'not-found'
          }]
        }
      },
      {
        path: "users/roles/:id",
        name: "Role",
        component: SingleRole,
        props: true,
      },

      {
        path: "users/redazioni",
        name: "Redazioni",
        component: RedazioneList,
      },
      {
        path: "users/:id",
        name: "User",
        component: SingleUser,
        props: true,
      },
      {
        path: "users/redazioni/:id",
        name: "RedazioneDetail",
        component: RedazioneDetail,
        props: true,
        meta: {
          permissions: [{
            role: 'redazione',
            access: false,
            redirect: 'not-found'
          }]
        }
      },
      {
        path: "user/profile",
        name: "Profilo Utente",
        component: UserProfile
      },
      {
        path: "user/logout",
        name: "Logout",
        component: Logout,
      },
      {
        path: "events",
        name: "Eventi",
        component: EventList,
      },
      {
        path: "licenze",
        name: "Licenze",
        component: LicenzeList,
      },
      {
        path: "licenze/:id",
        name: "Gestione Licenza",
        component: Licenza,
        props: true,
      },
      {
        path: "events/:id",
        name: "Gestione Evento",
        component: Event,
        props: true,
        meta: {
          permissions: [{
            role: 'redazione',
            access: false,
            redirect: 'not-found'
          }]
        }
      },
      {
        path: "news",
        name: "News",
        component: NewsList,

      },
      {
        path: "news/:id",
        name: "Gestione News",
        component: News,
        props: true,
        meta: {
          permissions: [{
            role: 'redazione',
            access: false,
            redirect: 'not-found'
          }]
        }
      },
      {
        path: "iat",
        name: "Uffici I.A.T.",
        component: IATList,

      },
      {
        path: "iat/:id",
        name: "Gestione Ufficio Informazione",
        component: IAT,
        props: true,
        meta: {
          permissions: [{
            role: 'redazione',
            access: false,
            redirect: 'not-found'
          }]
        }
      },
      {
        path: "itinerary",
        name: "Elenco Itinerari",
        component: ItinerariList,

      },
      {
        path: "itinerary/:id",
        name: "Gestione Itinerario",
        component: Itinerary,
        props: true,
        meta: {
          permissions: [{
            role: 'redazione',
            access: false,
            redirect: 'not-found'
          }]
        }
      },
      {
        path: "opendata-list",
        name: "Open Data",
        component: OpenDataList,
      },
      {
        path: "makexp-list",
        name: "MakeXp",
        component: MakeXPList,
      },
      {
        path: "trekksoft-list",
        name: "TrekkSoft",
        component: TrekkSoftList,
      },
      {
        path: "download-list",
        name: "Download",
        component: DownloadList,
      },
      {
        path: "viaemilia",
        name: "Elenco Via Emilia",
        component: ViaEmiliaList,
        props: true,

      },
      {
        path: "viaemilia/:id",
        name: "Gestione Viaemilia",
        component: ViaEmilia,
        props: true,

      },
      {
        path: "interessi-list/:main/:name_interest",
        name: "Elenco Interessi",
        component: InteressiList,
        props: true,

      },
      {
        path: "webcam-list",
        name: "Elenco Webcam",
        component: WebcamList,

      },
      {
        path: "page",
        name: "Elenco Pagine Statiche",
        component: PageList,

      },
      {
        path: "page/:id",
        name: "Gestione Pagine Statiche",
        component: Page,
        props: true,
        meta: {
          permissions: [{
            role: 'redazione',
            access: false,
            redirect: 'not-found'
          }]
        }
      },
      {
        path: "punti-interesse",
        name: "Elenco Tipologia Luoghi",
        component: TipologiaLuoghiList,

      },
      {
        path: "punti-interesse/:code",
        name: "Elenco POI",
        component: LuoghiList,
        props: true,

      },
      {
        path: "luogo/:id/:code",
        name: "Gestione Luogo",
        component: Luogo,
        props: true,
        meta: {
          permissions: [{
            role: 'redazione',
            access: false,
            redirect: 'not-found'
          }]
        }
      },
      {
        path: "category-list/:id",
        name: "Editor Categoria",
        props: true,
        component: CategoryList,

      },
      {
        path: "opendata/:id/:path",
        name: "Gestione OpenData",
        component: OpenData,
        props: true,
      },
      {
        path: "download/:id/:path",
        name: "Gestione Download",
        component: Download,
        props: true,
      },
      {
        path: "interessi/:id/:path/:theme",
        name: "Gestione Interessi",
        component: Interessi,
        props: true,
        meta: {
          permissions: [{
            role: 'redazione',
            access: false,
            redirect: 'not-found'
          }]
        }
      },
      {
        path: "place-list",
        name: "Place",
        component: PlaceList,

      },
      {
        path: "webcam/:id/:path",
        name: "Gestione Webcam",
        component: Webcam,
        props: true,
        meta: {
          permissions: [{
            role: 'redazione',
            access: false,
            redirect: 'not-found'
          }]
        }
      },
      {
        path: "gallery-frame/:alone",
        name: "media",
        component: Gallery,
        props: true
      },
      {
        path: "gallery-frame-entity/:alone",
        name: "Gestione Media",
        component: Gallery,
        props: true
      },
      {
        path: "error404-list",
        name: "Errori 404",
        component: Error404List,
      },
      {
        path: "home-settings",
        name: "Home",
        component: HomeSettings,
      },
      {
        path: "home-settings-extra",
        name: "Extra",
        component: HomeSettingsExtra,
      },
      {
        path: "tags",
        name: "Tags",
        component: Tags,
      },
      {
        path: "tags/:id",
        name: "TagsDetail",
        component: TagsDetail,
        props: true,
      },
      /* ROTTA PER I BLOGS */
      {
        path: "blog",
        name: "Blogs",
        component: BlogsList,

      },
      {
        path: "blog/:id",
        name: "Gestione Articoli",
        component: Blogs,
        props: true,

      },
      {
        path: "operatori",
        name: "Operatori",
        component: OperatoriList,

      },
      {
        path: "operatori/:id",
        name: "Gestione Operatore",
        component: Operatore,
        props: true,

      },
      /* ROTTA PROPOSTE DI SOGGIORNO */
      {
        path: "soggiorno",
        name: "Lista Proposte",
        component: ProposteSoggiornoList,

      },
      {
        path: "soggiorno/:id",
        name: "Gestione proposte",
        component: ProposteSoggiorno,
        props: true,

      },
      {
        path: "allegati",
        name: "Gestione Allegati",
        component: Allegati
      },
      {
        path: "esperienze",
        name: "Lista Esperienze",
        component: EsperienzeList,

      },
      {
        path: "esperienze/:id",
        name: "Gestione esperienze",
        component: Esperienza,
        props: true,

      },
      {
        path: "menu",
        name: "Gestione Menu",
        component: MenuEditor,
        props: false,
      },


      { name: "Lista Place", component: PlaceList, path: "places" },
      { name: "Lista Ristoranti", component: EatList, path: "restaurants" },
   //   { name: "Lista Itinerari", component: ItinerariCalabriaList, path: "itineraries" },
      { name: "Lista Guide", component: GuideList, path: "guides" },
      { name: "Lista Rent", component: RentList, path: "rent-a-car" },
      { name: "Lista Sport", component: SportList, path: "sport-wellness" },
      { name: "Lista Station", component: StationList, path: "stations" },
      { name: "Lista Hotel", component: StayList, path: "stay" },
      { name: "Lista Template", component: TemplateList, path: "templates" },
      { name: "Lista Destinazioni", component: DestinationList, path: "destinations" },

      { name: "Gestione Place", component: Place, path: "places/:id", props: true },
      { name: "Gestione Ristoranti", component: Eat, path: "restaurants/:id", props: true },
     // { name: "Gestione Itinerari", component: ItinerariCalabria, path: "itineraries/:id", props: true },
      { name: "Gestione Guide", component: Guide, path: "guides/:id", props: true },
      { name: "Gestione Rent", component: Rent, path: "rent-a-car/:id", props: true },
      { name: "Gestione Sport", component: Sport, path: "sport-wellness/:id", props: true },
      { name: "Gestione Station", component: Station, path: "stations/:id", props: true },
      { name: "Gestione Hotel", component: Stay, path: "stay/:id", props: true },
      { name: "Gestione Destinazioni", component: Destination, path: "destinations/:id", props: true },
      { name: "Gestione Template", component: Template, path: "templates/:id", props: true },

      { path: 'stats', redirect: 'stats/attendances' },
      { name: 'Presenza online', component: AttendancesStats, path: 'stats/attendances', props: true },
      { name: 'Servizi', component: ServicesStats, path: 'stats/services', props: true },
      { name: 'Valutazioni', component: RatesStats, path: 'stats/rates', props: true },
      { name: 'Ricerche online', component: SearchesStats, path: 'stats/searches', props: true },

    ]
  },
];

export default routes;
